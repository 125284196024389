












































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import moment from 'moment'
import Templates from '@/modules/whatsapp/views/templates.vue'

@Component({
    name: 'VsShareListCard',
    components: {
        VsListingCard,
        VsDropdownButton,
        Templates,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private list!: any

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private loadingSubscribers!: boolean

    get isDeleteDisabled () {
        if (this.list.last_campaign_sent_timestamp) {
            return moment().diff(moment.unix(this.list.last_campaign_sent_timestamp), 'days') <= 10
        }
        return false
    }

    get totalSubscribers () {
        return this.list?.total_contact?.data?.subscribers || 0
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canShareRecipient () {
        return this.userRules.shareRecipients
    }

    get canProfilation () {
        return this.userRules.segments
    }

    get universityUrl () {
        return get(AppModule, 'universityUrl', '')
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$root.$vsOpenBlockedModal()
        }
    }
}
