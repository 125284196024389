





















































































































































































































































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import moment from 'moment'
import Templates from '@/modules/whatsapp/views/templates.vue'

@Component({
    name: 'VsListCard',
    components: {
        VsListingCard,
        VsDropdownButton,
        VsSeparator,
        Templates,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private list!: any

    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private defaultSender!: boolean

    get isDeleteDisabled () {
        if (this.list.last_campaign_sent_timestamp) {
            return moment().diff(moment.unix(this.list.last_campaign_sent_timestamp), 'days') <= 10
        }
        return false
    }

    get totalSubscribers () {
        return this.list?.statistics?.data?.subscribers || 0
    }

    get open () {
        return this.list?.statistics?.data?.open || 0
    }

    get click () {
        return this.list?.statistics?.data?.click || 0
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canShareRecipient () {
        return this.userRules.shareRecipients
    }

    get canProfilation () {
        return this.userRules.segments
    }

    get universityUrl () {
        return get(AppModule, 'universityUrl', '')
    }

    get showLastCampaignBadge () {
        return this.list.last_campaign_sent_timestamp || (moment().diff(moment(this.list.created_at), 'days') > 30)
    }

    get lastCampaignSent () {
        const date = this.list.last_campaign_sent_timestamp
        switch (true) {
            case date === null || moment().diff(moment.unix(date), 'days') > 30:
                return {
                    type: 'warning',
                    title: this.$t('lists.listCard.lastCampaignSent.warning.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.warning.description'),
                }
            case moment().diff(moment.unix(date), 'days') <= 10:
                return {
                    type: 'success',
                    title: this.$t('lists.listCard.lastCampaignSent.success.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.success.description'),
                }
            case moment().diff(moment.unix(date), 'days') > 10 && moment().diff(moment.unix(date), 'days') <= 30:
                return {
                    type: 'info',
                    title: this.$t('lists.listCard.lastCampaignSent.info.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.info.description'),
                }
            default:
                return {
                    type: 'info',
                    title: '',
                    description: '',
                }
        }
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$root.$vsOpenBlockedModal()
        }
    }
}
