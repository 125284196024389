



















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsListsTopMultiActions from './../components/VsListsTopMultiActions/Index.vue'
import VsListCard from './../components/VsListCard/Index.vue'
import VsShareListCard from './../components/VsShareListCard/Index.vue'
import VsAssignLabelTagListModal from './../components/VsAssignLabelTagListModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsListSuppressionBox from './../components/VsListSuppressionBox/Index.vue'
import VsCloneListModal from './../components/VsCloneListModal/Index.vue'

import axios from 'axios'
import { get, groupBy, orderBy, union } from 'lodash'
import { UserModule } from '@/store/modules/user'

import {
    deleteList,
    deleteListBulk,
    updateList,
    getLists,
    getSharedLists,
} from '@/api/consoleApi/recipients'

import {
    getEntityLabels,
    removeEntityLabelToEntity,
} from '@/api/consoleApi/entityLabels'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsListNameModal from '@/modules/lists/components/VsListNameModal/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import { getSendersEmail } from '@/api/consoleApi/senders/emails'

@Component({
    name: 'ListsIndex',
    components: {
        VsListsTopMultiActions,
        VsDropdownButton,
        VsSectionHeader,
        VsAssignLabelTagListModal,
        VsConfirm,
        VsEmptyState,
        VsListCard,
        VsShareListCard,
        VsListSuppressionBox,
        VsCloneListModal,
        VsLoader,
        VsContainer,
        VsListNameModal,
        VsFilterContainer,
    },
})
export default class extends Vue {
    private loading = false
    private lists: any[] = []
    private checkedLists: any[] = []
    private labelTags: any[] = []
    private total = 0
    private userTotalLists = 0
    private cancelTokenSource = axios.CancelToken.source()
    private emptyState = true
    private firstStartLoading = true
    private deleteErrors = []
    private defaultSender = []
    private shareOptions = [
        {
            value: 'notShare',
            label: this.$t('lists.index.shareOptions.yourLists'),
        },
        {
            value: 'share',
            label: this.$t('lists.index.shareOptions.sharedList'),
        },
    ]

    private filters = {
        search: '',
        labelTags: [],
        share: 'notShare',
    }

    $refs!: {
        deleteListConfirm: VsConfirm
        listNameModal: VsListNameModal
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get user () {
        return UserModule.user
    }

    get filteredSharedLists () {
        if (this.filters.share !== 'share') return []
        return this.lists.filter(el => !this.filters.search || el.recipient.name.includes(this.filters.search))
    }

    get hasShop () {
        return this.user.configuration.rules.shop
    }

    get listLimit () {
        return this.user.configuration.rules.recipientLimit
    }

    get showListBanner () {
        if (this.listLimit > -1 && this.userTotalLists >= this.listLimit - 1) return true
        return false
    }

    get listsOrderBy () {
        return [
            {
                label: this.$t('lists.index.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('lists.index.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
            {
                label: this.$t('lists.index.orderBy.lastCampaignSentAtDesc'),
                value: 'last_campaign_sent_timestamp|desc',
            },
            {
                label: this.$t('lists.index.orderBy.lastCampaignSentAtAsc'),
                value: 'last_campaign_sent_timestamp|asc',
            },
        ]
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canShareRecipient () {
        return this.userRules.shareRecipients
    }

    get countFilters () {
        let count = 0
        if (this.filters.labelTags && this.filters.labelTags.length > 0) count++
        if (this.filters.search && this.filters.search.length > 0) count++
        if (this.filters.share && this.filters.share !== 'notShare') count++
        return count
    }

    async mounted () {
        this.getLabelTags()
        // await this.checkEmptyState()
        this.getDefaultSender()
        await this.getLists()
        this.firstStartLoading = false
    }

    private async getDefaultSender () {
        try {
            this.loading = true
            const resp = await getSendersEmail({
                page: 1,
                limit: 1,
                searchJoin: 'and',
                search: 'default:1',
            })
            this.defaultSender = resp.data.data
        } catch (e) {
            console.log(e)
            this.defaultSender = []
        }
        this.loading = false
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.page = 1
        this.getLists()
    }

    @Watch('pagination', { deep: true, immediate: false })
    async getLists () {
        this.lists = []
        if (this.filters.share === 'notShare') {
            this.getCommonLists()
        } else {
            this.getSharedLists()
        }
    }

    async getCommonLists () {
        this.loading = true
        try {
            await this.checkEmptyState()
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('cancel-token')
            }
            this.cancelTokenSource = axios.CancelToken.source()
            const resp = await getLists(this.buildParams(), this.cancelTokenSource.token)
            this.lists = resp.data.data
            this.total = resp.data.meta.pagination.total
            if (this.total > 0) this.emptyState = false
        } catch (e) {
            const message = get(e, 'message', '')
            if (message === 'cancel-token') return
            this.lists = []
            this.total = 0
        }
        this.loading = false
    }

    async getSharedLists () {
        this.loading = true
        try {
            const resp = await getSharedLists({ include: 'owner, total_contact' })
            const shared = groupBy(resp.data.data, 'recipient_id')
            const lists = []
            for (const share of Object.values(shared)) {
                lists.push(share[0])
            }

            let orderParam: any = 'created_at'
            let sortedBy: any = 'desc'

            if (this.pagination.orderBy) {
                const order = this.pagination.orderBy.split('|')
                orderParam = order[0]
                sortedBy = order[1]
            }

            this.lists = orderBy(lists, 'recipient.' + orderParam, sortedBy)
        } catch (e) {
            console.log(e)
            this.lists = []
        }
        this.loading = false
    }

    async checkEmptyState () {
        try {
            const resp = await getLists({ limit: 1 }, this.cancelTokenSource.token)
            this.userTotalLists = resp.data.meta.pagination.total
            this.emptyState = resp.data.meta.pagination.total === 0
        } catch (e) {}
    }

    private async getLabelTags () {
        try {
            const labelTag: any[] = []
            await this.loopCall(1, labelTag, getEntityLabels)
            this.labelTags = labelTag
        } catch (e) {
        }
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller({
            page,
            limit: 1000,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    private selectAll () {
        this.checkedLists = union(
            this.checkedLists,
            this.lists.map(el => el.id),
        )
    }

    private async deleteListBulk (listIds: any[]) {
        this.deleteErrors = []
        try {
            await this.$refs.deleteListConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            const resp = await deleteListBulk(listIds)

            const errorCount = resp.data.data && Array.isArray(resp.data.data) ? resp.data.data.length : 0
            if (errorCount > 0) {
                this.deleteErrors = resp.data.data
            }

            const totalDeleted = this.checkedLists.length - errorCount
            if (totalDeleted <= 0) {
                this.checkedLists = []
                this.loading = false
                return
            }

            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteSuccess', totalDeleted),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })

            this.checkedLists = []
            if (this.pagination.page === 1) {
                await this.getLists()
            } else {
                this.pagination.page = 1
            }
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteError', this.checkedLists.length === 0 ? 1 : this.checkedLists.length),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            await this.getLists()
            console.log(e)
        }
        this.loading = false
    }

    private async deleteList (listId: any) {
        try {
            await this.$refs.deleteListConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            const resp = await deleteList(listId)

            const errorCode = get(resp, 'data.data.motivation.code', '')
            if (errorCode === 'CannotDeleteRecipientUsedInRecentCampaign') {
                this.$root.$vsToast({
                    heading: `Errore durante la cancellazione della lista`,
                    message: 'Non è possibile cancellare liste che sono state usate per inviare campagne negli ultimi 10 giorni.',
                    timeout: 3000,
                    aspect: VsToastAspectEnum.alert,
                })
                this.loading = false
                return
            }

            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteSuccess', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.checkedLists = []
            if (this.pagination.page === 1) {
                await this.getLists()
            } else {
                this.pagination.page = 1
            }
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteError', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            await this.getLists()
            console.log(e)
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            include: 'statistics,label_tags',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.labelTags.length > 0 ? `label_tags.id:${this.filters.labelTags}` : '',
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.labelTags.length > 0 ? 'label_tags.id:in' : '',
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private tagAssigned () {
        this.getLists()
    }

    private tagCreated () {
        this.$root.$vsToast({
            heading: this.$t('lists.index.createTagSuccess'),
            timeout: 3000,
            aspect: VsToastAspectEnum.success,
        })
        this.getLabelTags()
    }

    private createListRedirect () {
        this.$router.push({ name: 'listCreate' })
    }

    private async editListName (listId: any, listName: string) {
        try {
            const name = await this.$refs.listNameModal.open(listName)
            try {
                await updateList(listId, { name })
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Nome della lista modificato con successo',
                    aspect: VsToastAspectEnum.success,
                })
                this.getLists()
            } catch (e) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Errore durante il salvataggio del nome della lista',
                    aspect: VsToastAspectEnum.alert,
                })
                return
            }
        } catch (e) {
            console.log(e)
        }
        this.$refs.listNameModal.close()
    }

    async deleteEntityLabel (id: any, listId: any) {
        try {
            await removeEntityLabelToEntity(id, 'recipients', listId)
            this.$root.$vsToast({
                heading: 'Tag rimosso con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getLists()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la rimozione del tag',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }
}
