



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFixedTopActions from '@/components/VsFixedTopActions/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsListsTopMultiActions',
    components: {
        VsFixedTopActions,
        VsSectionHeader,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private show = true
    $refs: any

    @Prop({ default: () => [], required: true }) checkedLists!: any[]

    private closeAndClear () {
        this.$emit('close-and-clear')
    }
}
