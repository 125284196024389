



























import { Component, Vue } from 'vue-property-decorator'
import {
    getSuppressions,
} from '@/api/consoleApi/suppressions'

@Component({
    name: 'VsListSuppressionBox',
})
export default class extends Vue {
    private count = 0

    mounted () {
        this.getSuppressionCount()
    }

    private async getSuppressionCount () {
        try {
            const resp = await getSuppressions({ limit: 1 })
            this.count = resp.data.meta.pagination.total || 0
        } catch (e) {
            console.log(e)
            this.count = 0
        }
    }
}
