
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCreateTagModal from '@/components/VsCreateTagModal/Index.vue'
import {
    assignEntityLabelToEntities,
} from '@/api/consoleApi/entityLabels'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsAssignLabelTagListModal',
    components: {
        VsCreateTagModal,
    },
})
export default class extends Vue {
    private listIds: number[] = []

    @Prop({
        default: () => [],
        required: false,
    }) labelTags!: any[]

    private show = false
    private loading = false
    private selectedTag = ''
    $refs: any

    private async assignTag () {
        this.loading = true
        try {
            await assignEntityLabelToEntities(this.selectedTag, 'recipients', { ids: this.listIds })
            this.$root.$vsToast({
                heading: this.$t('lists.index.addTagSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('tag-assigned')
            this.show = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    public openModal (listIds: number[]) {
        this.listIds = listIds
        this.selectedTag = ''
        this.show = true
    }
}
